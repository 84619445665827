import Reagan from 'assets/friends/reagan.jpg';
import Saad from 'assets/friends/saad.jpg';
import Bri from 'assets/friends/bri.jpg';
import Suzy from 'assets/friends/suzy.jpg';
import Maddie from 'assets/friends/maddie.jpg';
import Reb from 'assets/friends/reb.jpg';
import Jaspo from 'assets/friends/jaspo.jpg';
import Dan from 'assets/friends/dan.jpg';
import Nick from 'assets/friends/nick.jpg';
import BrysonAndShane from 'assets/friends/brysonandshane.jpg';
import Jarine from 'assets/friends/jarine.jpg';

export type FriendsContent = {
  img: string;
  name: string;
  title: string;
  bio: string;
};

export const FRIENDS_CONTENT: FriendsContent[] = [
  {
    img: Reagan,
    name: 'Reagan',
    title: 'Maid of Honour',
    bio: `Reagan is my little sister who I am eternally thankful for. She is always there when I need her, even if it's my 85th time calling her that day. Reagan is the best because she understands every joke I make and thinks I'm hilarious. We have endless memories together that I'll cherish (most of them) forever. -S`,
  },
  {
    img: Saad,
    name: 'Saad',
    title: 'Best Man',
    bio: `My friendship with Saad started in high school but now he is like a brother to me. Saad is one of the nicest people out there - he has helped me through a lot which I will always be grateful for. He has shaped me into the history-loving person that I am today and made me lose thousands of hours of my life to playing Civ 5. It's an honour to have Saad as my best man on the most important day of my life. -M`,
  },
  {
    img: Reb,
    name: 'Rebekah',
    title: 'Bridesmaid',
    bio: `I'm grateful that Matthew has an older sister like Rebekah. Her sense of humour is always making us laugh and her taste in snacks is unmatched. I love having another sister whose clothes I can steal. -S \n\n My older sister (누나) has always been around for me and is one of the toughest people I know. She is also the only person to consistently give me kudos on every Strava activity (smh Savannah) -M`,
  },
  {
    img: Jaspo,
    name: 'Jasper',
    title: 'Groomsman',
    bio: `Jasper is my brother-in-law but he's since become an older brother to both me and Savannah. He has the best music taste, cooking skills, and his love for Marshall is unmatched. Despite our age difference, Jasper has always treated us with a lot of respect while being an amazing role model to us. He is funny, reliable, and thoughtful and we are lucky to have him in our lives. I can also confirm that he is still with the times. -M`,
  },
  {
    img: Suzy,
    name: 'Suzy',
    title: 'Bridesmaid',
    bio: `Suzy and I have been friends since 2012 where we bonded over walking during recess at school. Since then, we've become best friends where instead of recess, we bond mostly over our cats, running, and interior design. I'm so thankful to have a friend as sweet and loyal as Suzy. -S`,
  },
  {
    img: Dan,
    name: 'Dan',
    title: 'Groomsman',
    bio: `Dan and I have been friends since high school and we ended up lived together in our first year of university (sorry about those 7AM alarms). Dan is one of the smartest guys I know although he's not that smart when it comes to Valorant. Dan's also a talented cook, although he doesn't really cook when he's playing Valorant. In all seriousness, Dan has always been a very close friend of mine and he's genuinely so fun to be around. "But we're camping..." -M`,
  },
  {
    img: Bri,
    name: 'Bri',
    title: 'Bridesmaid',
    bio: 'Bri and I were forced to live together as strangers in first year of university. Thankfully, we got along really well and are still close friends today. We eventually lived together in our last year of university (voluntarily this time). Bri is one of the hardworking people that I know but she is always ready for a good time, making her the perfect bridesmaid! -S',
  },
  {
    img: Nick,
    name: 'Nick',
    title: 'Groomsman',
    bio: `I met Nick when I was 4 years old but our friendship started during high school. Nick is an amazing friend - he is one of the most thoughtful and considerate people that I know. He kicks my ass in chess and bouldering but he only makes me feel bad about it sometimes. He also showed me The Room which I'm not completely sure how I feel about as I write this. -M`,
  },
  {
    img: Jarine,
    name: 'Jarine',
    title: 'Bridesmaid',
    bio: 'Jarine and I met in second year at WLU during a night out with mutual friends. Jarine and I instantly clicked and starting going out just the two of us. We were roommates in 2022 for a year when I moved to Toronto for grad school - in that year, we enjoyed a lot of wine, brie, and salami together. Jarine is the best person to go out with because she always has the best photos. -S',
  },
  {
    img: BrysonAndShane,
    name: 'Bryson & Shane',
    title: 'Groomsmen',
    bio: `Bryson and Shane are my two younger brothers who are the sweetest little guys; they're going to be the life of the party. Matthew and I are so grateful to have these two in our lives to keep us in the loop with Gen Alpha slang. If you run into them, talk to Bryson about basketball before he makes his debut in the NBA and talk to Shane about dinosaurs before he becomes a palaeontologist. -S`,
  },
  {
    img: Maddie,
    name: 'Maddie',
    title: 'Bridesmaid',
    bio: `Maddie has always been the cool, older cousin who has been always eager to take me out to cool places. She is like my older sister and I'm so thankful to have her in my life. She has always taken really good care for me and I look forward to more adventures in Toronto and Brooklin together. -S`,
  },
];
